<!--分析--能效KPI--年、月目标达成图表-->
<template>
  <div id="targetKPI">
      <div class="targetKPI-left">
          <div class="targetKPI-left-top">
              <div id="targetKPIType1" :style="{ width: '100%', height: '100%' }"></div>
          </div>
          <div class="targetKPI-left-bottom">
              <div id="targetKPIType2" :style="{ width: '100%', height: '100%' }"></div>
          </div>
      </div>
      <div class="targetKPI-right">
          <div id="targetKPIType3" :style="{ width: '100%', height: '100%' }"></div>
          <div class="legend">
              <div class="blue">{{ $t('analyseKPI.a23') }}</div>
              <div class="orange">{{ $t('analyseKPI.a24') }}</div>
          </div>
      </div>
  </div>
</template>

<script>
import { EleResize } from '../../../utils/esresize'

export default {
    props:{
        data:{
            type:Object
        },
        unit:{
            type:String
        },
        yearRuleMonth:{
            type:String
        },
        yearRule:{
            type:String
        }
    },
    data(){
        return{
            gaugeoptions1: {
                series: [
                    {
                        type: "gauge",
                        startAngle: 180,
                        endAngle: 0,
                        min: 0,
                        max: 100,
                        center: ["50%", "75%"],
                        radius: "120%",
                        itemStyle: {
                            color: ()=>{ return this.data.cumulativeElectricity <= 100 ? '#3399ff' :'#e60000'},
                            shadowColor: '#666666',
                            shadowBlur: 10,
                        },
                        progress: {
                            //上层进度条
                            show: true,
                            width: 50,
                        },
                        // 仪表盘指针
                        pointer: {
                            show: false,
                        },
                        axisLine: {
                            //下层总量条
                            lineStyle: {
                                width: 50,
                                color: [[1, "#ff9966"]],
                            },
                        },
                        //刻度样式
                        axisTick: {
                            show: false,
                        },
                        //分隔线
                        splitLine: {
                            show: false,
                        },
                        //刻度标签
                        axisLabel: {
                            show: false,
                        },
                        detail: {
                            show: true,
                            offsetCenter: [0, '-10%'],
                            formatter:(value)=> {
                                return "{value|" + value.toFixed(2) + '%}';
                            },
                            rich: {
                                value: {
                                    fontSize: '0.18rem',
                                    fontWeight: "bolder"
                                },
                            },
                        },
                        title:{
                            width:300,
                            overflow:'break'
                        },
                        data: [
                            {
                                value: this.data.cumulativeElectricity,
                                name:this.$t('analyseKPI.a25')
                            },
                        ],
                    },
                ],
            },
            gaugeoptions2: {
                series: [
                    {
                        type: "gauge",
                        startAngle: 180,
                        endAngle: 0,
                        min: 0,
                        max: 100,
                        center: ["50%", "75%"],
                        radius: "120%",
                        itemStyle: {
                            color: ()=>{ return this.data.predictElectricity <= 100 ? '#3399ff' :'#e60000'},
                            shadowColor: '#666666',
                            shadowBlur: 10,
                        },
                        progress: {
                            //上层进度条
                            show: true,
                            width: 50,
                        },
                        // 仪表盘指针
                        pointer: {
                            show: false,
                        },
                        axisLine: {
                            //下层总量条
                            lineStyle: {
                                width: 50,
                                color: [[1, "#ff9966"]],
                            },
                        },
                        //刻度样式
                        axisTick: {
                            show: false,
                        },
                        //分隔线
                        splitLine: {
                            show: false,
                        },
                        //刻度标签
                        axisLabel: {
                            show: false,
                        },
                        detail: {
                            show: true,
                            offsetCenter: [0, '-10%'],
                             formatter: "Cumulative power consumption progress",
                            formatter:(value)=> {
                                return "{value|" + value.toFixed(2) + '%}';
                            },
                            rich: {
                                value: {
                                    fontSize: '0.18rem',
                                    fontWeight: "bolder",
                                }
                            },
                        },
                        title:{
                            width:300,
                            overflow:'break'
                        },
                        data: [
                            {
                                value: this.data.predictElectricity,
                                name:this.$t('analyseKPI.a26')
                            },
                        ],
                    },
                ],
            },
            baroptions:{
                tooltip: {
                    trigger: 'axis',
                },
                grid:{
                    top:'35px',
                    left:'10px',
                    bottom:'30px',
                    right:'10px',
                    containLabel:true
                },
                xAxis: {
                    type: 'category',
                    data: []
                },
                yAxis: {
                    type: 'value',
                    name: `${this.$t('analyseKPI.a33')}(${this.unit})`,
                    nameTextStyle:{
                        align:'left'
                    }
                },
                series:[
                    {
                        type:"bar",
                        data:[]
                    }
                ]
            },
            targetKPIType1:undefined,
            targetKPIType2:undefined,
            targetKPIType3:undefined
        }
    },
    watch:{
        data:{
            handler(newValue){
                if(this.targetKPIType1 !== undefined){ this.targetKPIType1.dispose() }
                if(this.targetKPIType2 !== undefined){ this.targetKPIType2.dispose() }
                if(this.targetKPIType3 !== undefined){ this.targetKPIType3.dispose() }
                this.gaugeoptions1.series[0].data[0].value = newValue.cumulativeElectricity
                this.gaugeoptions2.series[0].data[0].value = newValue.predictElectricity
                this.drawGauge1()
                this.drawGauge2()
                const list = this.data.barData.map(item=>{ return {value:item.data[0],itemStyle:item.itemStyle}})
                const length = newValue.barData.length
                const xAxis = newValue.barData.map((item,index)=>{
                    if(length > 12){
                        return `${index + 1}`
                    }else{
                        if((Number(this.yearRuleMonth) + index) <= 12){
                            return `${Number(this.yearRule)}/${Number(this.yearRuleMonth) + index}`
                        }else{
                            return `${Number(this.yearRule) + 1}/${Number(this.yearRuleMonth) - (12 - index)}`
                        }
                    }
                })
                this.baroptions.series[0].data = list
                this.baroptions.xAxis.data = xAxis
                this.drawBar()
            },
            deep:true
        }
    },
    mounted(){
        this.drawGauge1()
        this.drawGauge2()
        const list = this.data.barData.map(item=>{ return {value:item.data[0],itemStyle:item.itemStyle}})
        const length = this.data.barData.length
        const xAxis = this.data.barData.map((item,index)=>{
            if(length > 12){
                return `${index + 1}`
            }else{
                if((Number(this.yearRuleMonth) + index) <= 12){
                    return `${Number(this.yearRule)}/${Number(this.yearRuleMonth) + index}`
                }else{
                    return `${Number(this.yearRule) + 1}/${Number(this.yearRuleMonth) - (12 - index)}`
                }
            }
        })
        this.baroptions.series[0].data = list
        this.baroptions.xAxis.data = xAxis
        this.drawBar()
    },
    methods:{
        // 折柱混合图
        drawGauge1() {
            let myChart = this.$echarts.init(document.getElementById('targetKPIType1'))
            let resizeDiv = document.getElementById('targetKPIType1')
            this.targetKPIType1 = myChart
            myChart.setOption(this.gaugeoptions1)
            let listener = () => {
                myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
        },
        drawGauge2() {
            let myChart = this.$echarts.init(document.getElementById('targetKPIType2'))
            let resizeDiv = document.getElementById('targetKPIType2')
            this.targetKPIType2 = myChart
            myChart.setOption(this.gaugeoptions2)
            let listener = () => {
                myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
        },
        drawBar() {
            let myChart = this.$echarts.init(document.getElementById('targetKPIType3'))
            let resizeDiv = document.getElementById('targetKPIType3')
            this.targetKPIType3 = myChart
            myChart.setOption(this.baroptions);
            let listener = () => {
                myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
        }
    }
}
</script>

<style scoped>
#targetKPI{
    display: flex;
    width: 100%;
    height: 100%;
}
.targetKPI-left{
    width: 30%;
    height: 100%;
}
.targetKPI-right{
    width: 70%;
    height: 100%;
    position: relative;
}
.targetKPI-left-top{
    width: 100%;
    height: calc(50% - 5px);
    margin-bottom: 10px;
}
.targetKPI-left-bottom{
    width: 100%;
    height: calc(50% - 5px);
}
.legend{
    display: flex;
    position: absolute;
    bottom: 0px;
    font-size: 16px;
    color: rgba(12,12,12,0.65);
    left: calc(50% - 65px);
}
.blue{
    display: flex;
    align-items: center;
    margin-right: 15px;
}
.blue::before{
    content:"";
    display:inline-block;
    width: 20px;
    height: 10px;
    background-color: #3399ff;
    margin-right: 5px;
    border-radius: 5px;
}
.orange::before{
    content:"";
    display:inline-block;
    width: 20px;
    height: 10px;
    background-color: #ff9966;
    margin-right: 5px;
    border-radius: 5px;
}
</style>