<!--分析--能效KPI-->
<template>
  <div id="analyseKPI" v-loading="loading" :element-loading-text="$t('analyseKPI.a1')">
      <!-- 页面头部标题 -->
        <div class="header">
            <my-PageTitle>{{ $t('analyseKPI.a2') }}</my-PageTitle>
        </div>
        <!-- 页面内容区域 -->
        <div class="main">
            <!-- 侧边电表树形及搜索栏 -->
            <div class="search">
                <a-input-search style="margin-bottom: 8px" :placeholder="$t('analyseKPI.a3')" @change="onChange" />
                <div class="tree">
                    <a-tree
                        :expanded-keys="expandedKeys"
                        :auto-expand-parent="autoExpandParent"
                        :tree-data="treeData "
                        :replace-fields="replaceFields"
                        @expand="onExpand"
                        :show-line="true"
                        @select="treeSelect"
                    >
                        <template slot="custom" slot-scope="item">
                            <span v-if="item.name.indexOf(searchValue) > -1">
                                {{ item.name.substr(0, item.name.indexOf(searchValue)) }}
                                <span style="color: #f50">{{ searchValue }}</span>
                                {{ item.name.substr(item.name.indexOf(searchValue) + searchValue.length) }}
                            </span>
                            <span v-else>{{ item.name }}</span>
                        </template>
                    </a-tree>
                </div>
            </div>
            <!-- 图表区域 -->
            <div class="chart">
                <!-- 图表区域头部条件选择栏 -->
                <div class="chart-header">
                    <div class="chart-header-left">
                        <!-- 年、月、日条件选择 -->
                        <a-select v-model="dateSelect" style="width: 80px;margin-right:15px">
                            <a-select-option v-for="(item,index) in rptKinds" :value="item.no" :key="index">
                                {{ item.text }}
                            </a-select-option>
                        </a-select>
                        <!-- 实际数值、目标达成条件选择 -->
                        <a-radio-group v-model="statisticKind" style="margin-right:15px" v-show="dateSelect !== '3'">
                            <a-radio-button :value="1">
                                {{ $t('analyseKPI.a4') }}
                            </a-radio-button>
                            <a-radio-button :value="2">
                                {{ $t('analyseKPI.a5') }}
                            </a-radio-button>
                        </a-radio-group>
                        <!-- 财年规则 -->
                        <a-select v-model="yearRuleSelect" style="width: 120px;margin-right:15px">
                            <a-select-option v-for="(item,index) in yearRuleData" :value="item.no" :key="index" @click="yearRuleChange(item)">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                        <div class="date">
                            <!-- 年条件数据值选择 -->
                            <transition name="slide-fade">
                                <div class="date-select" v-if="dateSelect == '1'">
                                    <a-select v-model="yearDateSelect" style="width: 120px;margin-right:15px">
                                        <a-select-option v-for="(item,index) in yearData" :value="item.value" :key="index">
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </div>
                            </transition>
                            <!-- 月条件数据值选择 -->
                            <transition name="slide-fade">
                                <div class="date-select" v-if="dateSelect == '2'">
                                    <a-month-picker v-model="monthDateSelect" :allowClear="false" style="width:120px" />
                                </div>
                            </transition>
                            <!-- 日条件数据值选择 -->
                            <transition name="slide-fade">
                                <div class="date-select" v-if="dateSelect == '3'">
                                    <a-date-picker v-model="dayDateSelect" :allowClear="false" style="width:150px" />
                                </div>
                            </transition>
                        </div>
                    </div>
                    <div>
                        <!-- 查询按钮及设置按钮 -->
                        <a-button-group>
                            <a-button type="primary" ghost @click="query">{{ $t('analyseKPI.a6') }}</a-button>
                            <a-button type="primary" ghost @click="CSVdownload" :loading="butLoading">{{ $t('analyseKPI.a7') }}</a-button>
                        </a-button-group>
                    </div>
                </div>
                <!-- 图表 -->
                <div class="chart-main">
                    <!-- 无图表状态 -->
                    <a-empty v-if="show == '0'">
                        <span class="explain">
                            {{ $t('analyseKPI.a8') }}
                        </span>
                    </a-empty>
                    <!-- 设备状态 -->
                    <a-empty v-if="show == '4'">
                        <span class="explain">
                            {{ statusIndicators }}
                        </span>
                    </a-empty>
                    <!-- 年、月 + 实际数值图表 -->
                    <div class="echarts"  v-if="show == '1'">
                         <my-actualKPI :data="echartsData1" :unit="unit" :yearRuleMonth="yearRuleMonth" :yearRule="yearRule" :dateSelect="dateSelect"></my-actualKPI>
                    </div>
                    <!-- 日 + 实际数值、目标达成图表 -->
                    <div class="echarts"  v-if="show == '2'">
                        <my-dayKPI :data="echartsData2" :unit="unit"></my-dayKPI>
                    </div>
                    <!-- 年、月 + 目标达成图表 -->
                    <div class="echarts"  v-if="show == '3'">
                        <my-targetKPI :data="echartsData3" :unit="unit" :yearRuleMonth="yearRuleMonth" :yearRule="yearRule"></my-targetKPI>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>   

<script>
import PageTitle from '../../components/Title/PageTitle.vue'
import actualKPI from './components/actualKPI.vue'
import targetKPI from './components/targetKPI.vue'
import dayKPI from './components/dayKPI.vue'
import { KPIInitialize,KPICSVdownload } from '../../api/energy'
import moment from 'moment'

export default {
    components:{
        'my-PageTitle':PageTitle,
        'my-actualKPI':actualKPI,
        'my-targetKPI':targetKPI,
        'my-dayKPI':dayKPI
    },
    data(){
        return{
            loading:false,
            fiscalYear:[],
            rptKinds:[],
            treeData:[],
            treeList:[],
            yearData:[],
            yearRuleData:[],
            expandedKeys: [],
            searchValue: '',
            autoExpandParent: true,
            replaceFields: { children: 'children',title: 'name',key:'no' },
            dateSelect:'1',
            yearRuleSelect:0,
            yearDateSelect:'',
            monthDateSelect:moment(),
            dayDateSelect:moment(),
            treeNodeData:{},
            statisticKind:1,
            show:'0',
            echartsData1:{
                monthActualvalue:[],
                monthTargetValue:[],
                yearAggregatValue:[],
                yearTargetValue:[]
            },
            echartsData2:{},
            echartsData3:{},
            unit:'',
            yearRuleMonth:'',
            yearRule:'',
            butLoading:false,
            statusIndicators:''
        }
    },
    mounted(){
        // 能效KPI显示页面数据初始化
        this.loading = true
        const data = {
            action: 0,
            clientId: this.$store.getters.clientId,
            sitegrId: this.$store.getters.sitegrId,
            siteId: this.$route.query.id,
            tenantId: 0,
            serviceType:8
        }
        KPIInitialize(data)
        .then(res=>{
            console.log(res)
            const {  data:{ fiscalYear, rptKinds, treeData, yearData, yearRuleData} } = res
            this.fiscalYear = fiscalYear
            this.rptKinds = rptKinds
            this.treeData = treeData
            this.yearData = yearData
            this.yearRuleData = yearRuleData
            this.yearRuleSelect = yearRuleData[0].no
            this.yearRuleMonth = yearRuleData[0].value
            this.yearDateSelect = yearData[0].value
            this.generateList(treeData)
            this.loading = false
        })
        .catch(err=>{
            console.log(err)
            this.loading = false
        })
    },
    methods:{
        moment,
        // 获取搜索列表
        generateList(data){
            for (let i = 0; i < data.length; i++) {
                    data[i].scopedSlots = { title:'custom' }
                    const node = data[i]
                    const no = node.no
                    this.treeList.push({ no, name: node.name })
                    if (node.children) { this.generateList(node.children) }
            }
        },
        // 搜索函数
        onChange(e) {
            const value = e.target.value
            const expandedKeys = this.treeList
                .map(item => {
                    if (item.name.indexOf(value) > -1) {
                        return this.getParentKey(item.no, this.treeData)
                    }
                        return null
                    })
                .filter((item, i, self) => item && self.indexOf(item) === i )
            Object.assign(this, {
                expandedKeys,
                searchValue: value,
                autoExpandParent: true
            })
        },
        // 侧边栏递归函数
        getParentKey(no, tree){
            let parentKey
            for (let i = 0; i < tree.length; i++) {
                const node = tree[i]
                if (node.children) {
                    if (node.children.some(item => item.no === no)) {
                        parentKey = node.no
                    } else if (this.getParentKey(no, node.children)) {
                        parentKey = this.getParentKey(no, node.children)
                    }
                }
            }
            return parentKey;
        },
        // 树形控件展开/收起节点方法
        onExpand(expandedKeys) {
            this.expandedKeys = expandedKeys;
            this.autoExpandParent = false;
        },
        // 侧边树形节点选择回调函数
        treeSelect(selectedKeys, info){
            const selected = info.selected
            if(selected){
                this.treeNodeData = info.node.dataRef
            }
        },
        // 查询方法
        query(){
            if(!this.treeNodeData.category){
                this.$warning({
                    title: this.$t('analyseKPI.a9'),
                    content: this.$t('analyseKPI.a10'),
                    centered: true
                })
            }else{
                this.loading = true
                const kind = this.dateSelect
                const data = {
                    action:1,
                    clientId:this.$store.getters.clientId,
                    sitegrId:this.$store.getters.sitegrId,
                    siteId:this.$route.query.id,
                    tenantId:0,
                    ruleId:this.yearRuleSelect,			
                    kind:this.dateSelect,				
                    statisticKind:this.statisticKind,	
                    serviceType:8,
                    year:'',			
                    pointType:this.treeNodeData.category,			
                    pointId:this.treeNodeData.no			
                }
                if(kind == '1'){
                    data.year = this.yearDateSelect
                    this.yearRule = this.yearDateSelect
                }else if(kind == '2'){
                    data.year = this.monthDateSelect.format('YYYY')
                    data.month = this.monthDateSelect.format('M')
                }else if(kind == '3'){
                    data.year = this.dayDateSelect.format('YYYY')
                    data.month = this.dayDateSelect.format('M')
                    data.day = this.dayDateSelect.format('D')
                }
                KPIInitialize(data)
                .then(res=>{
                    console.log(res)
                    if(res.errorCode == '00'){
                        const dateSelect = this.dateSelect
                        const statisticKind = this.statisticKind
                        if(dateSelect !== '3' && statisticKind == '1'){
                            this.show = '1'
                            const { data:{ data,unit } } = res
                            this.echartsData1 = data
                            this.unit = unit
                        }else if(dateSelect !== '3' && statisticKind == '2'){
                            this.show = '3'
                            const { data:{ targetData,unit } } = res
                            this.echartsData3 = targetData
                            this.unit = unit
                        }else if(dateSelect == '3'){
                            this.show = '2'
                            const { data:{ dayData,unit } } = res
                            this.echartsData2 = dayData
                            this.unit = unit
                        }
                    }else{
                        this.show = '4'
                        this.statusIndicators = res.msg
                    }
                    this.loading = false
                })
                .catch(err=>{
                    console.log(err)
                    this.loading = false
                })
            }
        },
        // CSV报表下载
        CSVdownload(){
            const pointList = this.treeNodeData.key
            if(!pointList){
                this.$warning({
                    title: this.$t('analyseKPI.a9'),
                    centered: true
                })
            }else{
                this.butLoading = true
                const kind = this.dateSelect
                let name = ''
                const data = {
                    action:1,
                    clientId:this.$store.getters.clientId,
                    sitegrId:this.$store.getters.sitegrId,
                    siteId:this.$route.query.id,
                    tenantId:0,
                    ruleId:this.yearRuleSelect,			
                    kind:this.dateSelect,				
                    statisticKind:this.statisticKind,	
                    serviceType:8,
                    year:'',
                    pointList:[pointList]						
                }
                if(kind == '1'){
                    data.year = this.yearDateSelect
                    this.yearRule = this.yearDateSelect
                    name = this.$t('analyseKPI.a10')
                }else if(kind == '2'){
                    data.year = this.monthDateSelect.format('YYYY')
                    data.month = this.monthDateSelect.format('M')
                    name = this.$t('analyseKPI.a11')
                }else if(kind == '3'){
                    data.year = this.dayDateSelect.format('YYYY')
                    data.month = this.dayDateSelect.format('M')
                    data.day = this.dayDateSelect.format('D')
                    name = this.$t('analyseKPI.a12')
                }
                KPICSVdownload(data)
                .then(res=>{
                    // console.log(res)
                    let url = window.URL.createObjectURL(res)
                    let link = document.createElement("a")
                    link.style.display = "none"
                    link.href = url
                    link.setAttribute("download", `${name}${this.$t('analyseKPI.a13')}.zip`)
                    document.body.appendChild(link)
                    link.click()
                    this.butLoading = false
                })
                .catch(err=>{
                    console.log(err)
                    this.butLoading = false
                })
            }
        },
        // 财年选择方法
        yearRuleChange(item){
            this.yearRuleMonth = item.value
        }
    }
}
</script>

<style scoped>
#analyseKPI{
    width: 100%;
    height: 100%;
}
.main{
    display: flex;
    width: 100%;
    height: calc(100% - 50px);
    padding: 20px 20px 10px 20px;
}
.search{
    width: 250px;
    margin-right: 15px;
}
.tree{
    height: calc(100% - 30px);
    border-right: 1px solid rgba(12, 12, 12, 0.25);
    overflow: auto;
}
div /deep/.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background-color: #7682ce;
}
.chart{
    width: calc(100% - 265px);
}
.chart-header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
}
.chart-header-left{
    display: flex;
}
.date{
    position: relative;
}
.date-select{
    width: 400px;
    position: absolute;
}
.chart-main{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 48px);
}
.explain{
    font-size: 20px;
    color: #7682ce;
}
.echarts{
    width: 100%;
    height: 100%;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>