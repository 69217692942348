<!--分析--能效KPI--年、月实际数值图表-->
<template>
  <div id="actualKPI">
      <div id="actualKPIType" :style="{ width: '100%', height: '100%' }"></div>
  </div>
</template>

<script>
import { EleResize } from '../../../utils/esresize'

export default {
    props:{
        data:{
            type:Object
        },
        unit:{
            type:String
        },
        yearRuleMonth:{
            type:String
        },
        yearRule:{
            type:String
        },
        dateSelect:{
            type:String
        }
    },
    data(){
        return{
            echarts:undefined,
            lineBarOption:{
                tooltip: {
                    trigger: 'axis'
                },
                grid:{
                    top:'40px',
                    left:'10px',
                    bottom:'30px',
                    right:'10px',
                    containLabel:true
                },
                legend: {
                    data: [],
                    bottom: 0
                },
                xAxis: [
                    {
                        type: 'category',
                        data: [],
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '',
                        min: 0,
                        nameTextStyle:{
                            align:'left'
                        }
                    },
                    {
                        type: 'value',
                        name: '',
                        min: 0,
                    }
                ],
                series: [
                    {
                        name: '',
                        type: 'line',
                        yAxisIndex: 1,
                        itemStyle: {
                            color: "rgba(153, 51, 255, 0.8)"
                        },
                        data: this.data.yearTargetValue
                    },
                    {
                        name: '',
                        type: 'bar',
                        itemStyle: {
                            color: "rgba(231, 112, 26, 1)"
                        },
                        data: this.data.monthTargetValue
                    },
                    {
                        name: '',
                        type: 'bar',
                        itemStyle: {
                            color: "rgba(96, 156, 239, 1)"
                        },
                        data: this.data.monthActualvalue
                    },
                    {
                        name: '',
                        type: 'line',
                        yAxisIndex: 1,
                        data: this.data.yearAggregatValue
                    }
                ]
            }
        }
    },
    watch:{
        data:{
            handler(newValue){
                if(this.echarts !== undefined){ this.echarts.dispose() }
                const length = newValue.monthActualvalue.length
                const data = newValue.monthActualvalue.map((item,index)=>{
                    if(length > 12){
                        return `${index + 1}`
                    }else{
                        if((Number(this.yearRuleMonth) + index) <= 12){
                            return `${Number(this.yearRule)}/${Number(this.yearRuleMonth) + index}`
                        }else{
                            return `${Number(this.yearRule) + 1}/${Number(this.yearRuleMonth) - (12 - index)}`
                        }
                    }
                })
                this.lineBarOption.xAxis[0].data = data
                this.lineBarOption.series[0].data = newValue.yearTargetValue
                this.lineBarOption.series[1].data = newValue.monthTargetValue
                this.lineBarOption.series[2].data = newValue.monthActualvalue
                this.lineBarOption.series[3].data = newValue.yearAggregatValue
                const date = this.dateSelect
                if(date == '1'){
                    this.lineBarOption.legend.data = [this.$t('analyseKPI.a14'),this.$t('analyseKPI.a15'),this.$t('analyseKPI.a16'),this.$t('analyseKPI.a17')]
                    this.lineBarOption.series[0].name = this.$t('analyseKPI.a14')
                    this.lineBarOption.series[1].name = this.$t('analyseKPI.a15')
                    this.lineBarOption.series[2].name = this.$t('analyseKPI.a16')
                    this.lineBarOption.series[3].name = this.$t('analyseKPI.a17')
                    this.lineBarOption.yAxis[0].name = this.$t('analyseKPI.a21') + `(${this.unit})`
                    this.lineBarOption.yAxis[1].name = this.$t('analyseKPI.a22') + `(${this.unit})`
                }else if(date == '2'){
                    this.lineBarOption.legend.data = [this.$t('analyseKPI.a15'),this.$t('analyseKPI.a18'),this.$t('analyseKPI.a19'),this.$t('analyseKPI.a20')]
                    this.lineBarOption.series[0].name = this.$t('analyseKPI.a15')
                    this.lineBarOption.series[1].name = this.$t('analyseKPI.a18')
                    this.lineBarOption.series[2].name = this.$t('analyseKPI.a19')
                    this.lineBarOption.series[3].name = this.$t('analyseKPI.a20')
                    this.lineBarOption.yAxis[0].name = this.$t('analyseKPI.a32') + `(${this.unit})`
                    this.lineBarOption.yAxis[1].name = this.$t('analyseKPI.a21') + `(${this.unit})`
                }
                this.drawLineBar()
            },
            deep:true
        }
    },
    mounted(){
        const length = this.data.monthActualvalue.length
        const data = this.data.monthActualvalue.map((item,index)=>{
            if(length > 12){
                return `${index + 1}`
            }else{
                if((Number(this.yearRuleMonth) + index) <= 12){
                    return `${Number(this.yearRule)}/${Number(this.yearRuleMonth) + index}`
                }else{
                    return `${Number(this.yearRule) + 1}/${Number(this.yearRuleMonth) - (12 - index)}`
                }
            }
        })
        this.lineBarOption.xAxis[0].data = data
        this.lineBarOption.series[0].data = this.data.yearTargetValue
        this.lineBarOption.series[1].data = this.data.monthTargetValue
        this.lineBarOption.series[2].data = this.data.monthActualvalue
        this.lineBarOption.series[3].data = this.data.yearAggregatValue
        const date = this.dateSelect
        if(date == '1'){
            this.lineBarOption.legend.data = [this.$t('analyseKPI.a14'),this.$t('analyseKPI.a15'),this.$t('analyseKPI.a16'),this.$t('analyseKPI.a17')]
            this.lineBarOption.series[0].name = this.$t('analyseKPI.a14')
            this.lineBarOption.series[1].name = this.$t('analyseKPI.a15')
            this.lineBarOption.series[2].name = this.$t('analyseKPI.a16')
            this.lineBarOption.series[3].name = this.$t('analyseKPI.a17')
            this.lineBarOption.yAxis[0].name = this.$t('analyseKPI.a21') + this.unit
            this.lineBarOption.yAxis[1].name = this.$t('analyseKPI.a22') + this.unit
        }else if(date == '2'){
            this.lineBarOption.legend.data = [this.$t('analyseKPI.a15'),this.$t('analyseKPI.a18'),this.$t('analyseKPI.a19'),this.$t('analyseKPI.a20')]
            this.lineBarOption.series[0].name = this.$t('analyseKPI.a15')
            this.lineBarOption.series[1].name = this.$t('analyseKPI.a18')
            this.lineBarOption.series[2].name = this.$t('analyseKPI.a19')
            this.lineBarOption.series[3].name = this.$t('analyseKPI.a20')
            this.lineBarOption.yAxis[0].name = this.$t('analyseKPI.a32') + this.unit
            this.lineBarOption.yAxis[1].name = this.$t('analyseKPI.a21') + this.unit
        }
        this.drawLineBar()
    },
    methods:{
        // 折柱混合图
        drawLineBar() {
            let myChart = this.$echarts.init(document.getElementById('actualKPIType'))
            let resizeDiv = document.getElementById('actualKPIType')
            this.echarts = myChart
            myChart.setOption(this.lineBarOption)
            let listener = () => {
                myChart.resize()
            }
            EleResize.on(resizeDiv, listener)
        }
    }
}
</script>

<style scoped>
#actualKPI{
    width: 100%;
    height: 100%;
}
</style>