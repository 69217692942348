<!-- 能效KPI 日--实际数值、目标达成 -->
<template>
  <div id="dayKPI">
      <div class="dayKPI-left">
          <div class="dayKPI-left-top">
              <div id="dayKPIType1" :style="{ width: '100%', height: '100%' }"></div>
          </div>
          <div class="dayKPI-left-bottom">
              <div id="dayKPIType2" :style="{ width: '100%', height: '100%' }"></div>
          </div>
      </div>
      <div class="dayKPI-right">
          <div id="dayKPIType3" :style="{ width: '100%', height: '100%' }"></div>
      </div>
  </div>
</template>

<script>
import { EleResize } from '../../../utils/esresize'

export default {
    props:{
        data:{
            type:Object
        },
        unit:{
            type:String
        }
    },
    data(){
        return{
            gaugeoptions1: {
                series: [
                    {
                        type: "gauge",
                        startAngle: 180,
                        endAngle: 0,
                        min: 0,
                        max: 100,
                        center: ["50%", "75%"],
                        radius: "120%",
                        itemStyle: {
                            color: ()=>{ return this.data.cumulativeElectricity <= 100 ? '#3399ff' :'#e60000'},
                            shadowColor: '#666666',
                            shadowBlur: 10,
                        },
                        progress: {
                            //上层进度条
                            show: true,
                            width: 50,
                        },
                        // 仪表盘指针
                        pointer: {
                            show: false,
                        },
                        axisLine: {
                            //下层总量条
                            lineStyle: {
                                width: 50,
                                color: [[1, "#ff9966"]],
                            },
                        },
                        //刻度样式
                        axisTick: {
                            show: false,
                        },
                        //分隔线
                        splitLine: {
                            show: false,
                        },
                        //刻度标签
                        axisLabel: {
                            show: false,
                        },
                        detail: {
                            show: true,
                            offsetCenter: [0, '-10%'],
                            formatter:(value)=> {
                                return "{value|" + value.toFixed(2) + '%}';
                            },
                            rich: {
                                value: {
                                    fontSize: '0.2rem',
                                    fontWeight: "bolder",
                                },
                            },
                        },
                        title:{
                            width:300,
                            overflow:'break'
                        },
                        data: [
                            {
                                value: 0,
                                name:this.$t('analyseKPI.a25'),
                            },
                        ],
                    },
                ],
            },
            gaugeoptions2: {
                series: [
                    {
                        type: "gauge",
                        startAngle: 180,
                        endAngle: 0,
                        min: 0,
                        max: 100,
                        center: ["50%", "75%"],
                        radius: "120%",
                        itemStyle: {
                            color: ()=>{ return this.data.predictElectricity <= 100 ? '#3399ff' :'#e60000'},
                            shadowColor: '#666666',
                            shadowBlur: 10,
                        },
                        progress: {
                            //上层进度条
                            show: true,
                            width: 50,
                        },
                        // 仪表盘指针
                        pointer: {
                            show: false,
                        },
                        axisLine: {
                            //下层总量条
                            lineStyle: {
                                width: 50,
                                color: [[1, "#ff9966"]],
                            },
                        },
                        //刻度样式
                        axisTick: {
                            show: false,
                        },
                        //分隔线
                        splitLine: {
                            show: false,
                        },
                        //刻度标签
                        axisLabel: {
                            show: false,
                        },
                        detail: {
                            show: true,
                            offsetCenter: [0, '-10%'],
                            formatter:(value)=> {
                                return "{value|" + value.toFixed(2) + '%}';
                            },
                            rich: {
                                value: {
                                    fontSize: '0.18rem',
                                    fontWeight: "bolder",
                                },
                            },
                        },
                        title:{
                            width:300,
                            overflow:'break'
                        },
                        data: [
                            {
                                value: 0,
                                name:this.$t('analyseKPI.a26')
                            },
                        ],
                    },
                ],
            },
            baroptions:{
                tooltip: {
                    trigger: 'axis',
                },
                grid:{
                    top:'35px',
                    left:'15px',
                    bottom:'30px',
                    right:'10px',
                    containLabel:true
                },
                legend: {
                    data: [this.$t('analyseKPI.a27'), this.$t('analyseKPI.a28'),this.$t('analyseKPI.a29')],
                    bottom: 0
                },
                xAxis: {
                    type: 'category',
                    data: ['01:00', '02:00', '03:00', '04:00', '05:00', '06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','24:00']
                },
                yAxis: [
                    {
                        type: 'value',
                        name: `${this.$t('analyseKPI.a30')}(${this.unit})`,
                        min: 0,
                        nameTextStyle: {
                          align: "left"
                        }
                    },
                    {
                        type: 'value',
                        name: `${this.$t('analyseKPI.a31')}(${this.unit})`,
                        min: 0,
                        nameTextStyle: {
                          align: "right"
                        }
                    }
                ],
                series: [
                    {
                        name:this.$t('analyseKPI.a27'),
                        data: [],
                        type: 'bar',
                        itemStyle: {
                            color: "rgba(96, 156, 239, 1)"
                        },
                    },
                     {
                        name: this.$t('analyseKPI.a28'),
                        type: 'line',
                        yAxisIndex: 1,
                        itemStyle: {
                            color: "rgba(231, 112, 26, 1)"
                        },
                        data: []
                    },
                    {
                        name: this.$t('analyseKPI.a29'),
                        type: 'line',
                        yAxisIndex: 1,
                        data: []
                    }
                ]
            },
            dayKPIEcharts1:undefined,
            dayKPIEcharts2:undefined,
            dayKPIEcharts3:undefined
        }
    },
    watch:{
        data:{
            handler(newValue){
                if(this.dayKPIEcharts1 !== undefined){ this.dayKPIEcharts1.dispose() }
                if(this.dayKPIEcharts2 !== undefined){ this.dayKPIEcharts2.dispose() }
                if(this.dayKPIEcharts3 !== undefined){ this.dayKPIEcharts3.dispose() }
                this.gaugeoptions1.series[0].data[0].value = this.data.cumulativeElectricity 
                this.gaugeoptions2.series[0].data[0].value = this.data.predictElectricity
                this.baroptions.series[2].data = this.data.cumulative
                this.baroptions.series[1].data = this.data.target
                this.baroptions.series[0].data = this.data.electricity
                this.drawgauge1()
                this.drawgauge2()
                this.drawBar()
            },
            deep:true
        }
    },
    mounted(){
        console.log(this.data)
        this.gaugeoptions1.series[0].data[0].value = this.data.cumulativeElectricity 
        this.gaugeoptions2.series[0].data[0].value = this.data.predictElectricity
        this.baroptions.series[2].data = this.data.cumulative
        this.baroptions.series[1].data = this.data.target
        this.baroptions.series[0].data = this.data.electricity
        this.drawgauge1()
        this.drawgauge2()
        this.drawBar()
    },
    methods:{
        // 折柱混合图
        drawgauge1() {
            let myChart = this.$echarts.init(document.getElementById('dayKPIType1'))
            let resizeDiv = document.getElementById('dayKPIType1')
            this.dayKPIEcharts1 = myChart
            myChart.setOption(this.gaugeoptions1)
            let listener = () => {
                myChart.resize()
            };
            EleResize.on(resizeDiv, listener)
        },
        drawgauge2() {
            let myChart = this.$echarts.init(document.getElementById('dayKPIType2'))
            let resizeDiv = document.getElementById('dayKPIType2')
            this.dayKPIEcharts2 = myChart
            myChart.setOption(this.gaugeoptions2)
            let listener = () => {
                myChart.resize()
            };
            EleResize.on(resizeDiv, listener)
        },
        drawBar() {
            let myChart = this.$echarts.init(document.getElementById('dayKPIType3'))
            let resizeDiv = document.getElementById('dayKPIType3')
            this.dayKPIEcharts3 = myChart
            myChart.setOption(this.baroptions)
            let listener = () => {
                myChart.resize()
            };
            EleResize.on(resizeDiv, listener)
        }
    }
}
</script>

<style scoped>
#dayKPI{
    display: flex;
    width: 100%;
    height: 100%;
}
.dayKPI-left{
    width: 30%;
    height: 100%;
}
.dayKPI-right{
    width: 70%;
    height: 100%;
}
.dayKPI-left-top{
    width: 100%;
    height: calc(50% - 5px);
    margin-bottom: 10px;
}
.dayKPI-left-bottom{
    width: 100%;
    height: calc(50% - 5px);
}
</style>